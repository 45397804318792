import React from 'react';
import './cookie-policy.scss';

/**
 * Cookie policy page document
 */
export const CookiePolicy = () => {
  return (
    <div id="cookie-policy">
      <div>
        <h1>Cookie Policy</h1>
      </div>
      <div>
        <hr />
        <section id="cookie-explanation">
          <p>
            How we use cookies to improve your browsing experience on this
            website.
          </p>
          <p>
            In this Cookies Policy, any use of the words "you", "yours" or
            similar expressions shall mean any user of this website whatsoever.
            Terms such as "we", "us, "our" or similar expressions shall mean the
            Aphex Group.
          </p>
          <p>
            Cookies are small pieces of information that are stored by your
            browser on your computer’s hard drive. They are often used as a
            mechanism for websites to remember useful information, such as your
            login details, and can contribute to ease of use when navigating a
            website. Our cookies cannot identify you personally.
          </p>
        </section>
        <section id="cookie-usage">
          <h2>HOW WE USE COOKIES</h2>
          <ul id="cookie-types">
            <li>
              Analytical/Performance Cookies: These allow us to recognise and
              count the number of users of our website and see how such users
              navigate through our website. This helps to improve how our
              website works, for example, by ensuring that users can find what
              they are looking for easily.
            </li>
            <li>
              Targeting Cookies: These record your visit to our website, the
              pages you have visited and the links you have followed. We will
              then use this information to make advertising displayed on it more
              relevant to your interests.
            </li>
          </ul>
          <p>The following table list the cookies used on our website:</p>
          <table id="cookies-table">
            <thead>
              <tr>
                <th>Cookie name</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_gid, _gat_gtag..., _gac_..., _ga, _gcl_...</td>
                <td>
                  These cookies enable us to anonymously track how visitors
                  access and browse our website, thereby enabling us to optimise
                  and improve our service.
                </td>
              </tr>
              <tr>
                <td>cookieConsent</td>
                <td>
                  This cookie stores whether the user has accepted the usage of
                  cookies.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            You can manage cookies by activating the setting on your Internet
            browser that allows you to refuse the setting of all or some
            cookies.
          </p>
          <p>
            Please note, if you do turn cookies off, this will limit the service
            that we are able to provide to you and may affect your user
            experience.
          </p>
        </section>
        <section id="cookie-policy-changes">
          <h2>CHANGES TO THIS COOKIES POLICY</h2>
          <p>
            Our policies and procedures are under continual review. We may, from
            time to time, update our cookies policy. Any such changes will be
            posted on this page.
          </p>
        </section>
        <section id="cookie-policy-contact">
          <h2>CONTACT</h2>
          <p>
            Questions, comments and requests regarding this Cookie Policy should
            be addressed to info@theaphexgroup.com or to Aphex Group Ltd,
            ArcLabs Research &amp; Innovation Centre, Waterford Institute of
            Technology West Campus, Carriganore, Co. Waterford, Ireland, X91
            P20H.
          </p>
        </section>
      </div>
    </div>
  );
};
