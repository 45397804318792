import React from 'react';
import { NavBar } from '../components/navbar';

import '../scss/global.scss';
import { Footer } from '../components/footer';
import { CookiePolicy } from '../components/cookie-policy';

export default function () {
  return (
    <>
      <NavBar />
      <CookiePolicy />
      <Footer />
    </>
  );
}
